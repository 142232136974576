const initialState = {
  loading: false,
  totalSupply: 0,
  whitelistMintedAmount:0,
  publicMintedAmount:0,
  maxWLMintAmount:0,
  // maxMintAmount:0,
  isWhitelisted:false,
  saleStage:0,
  // cost: 0,
  error: false,
  errorMsg: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        totalSupply: action.payload.totalSupply,
        whitelistMintedAmount: action.payload.whitelistMintedAmount,
        maxWLMintAmount: action.payload.maxWLMintAmount,
        isWhitelisted: action.payload.isWhitelisted,
        saleStage: action.payload.saleStage,
        // cost: action.payload.cost,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
