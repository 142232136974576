// log
import store from "../store";
import keccak256 from 'keccak256'
import { MerkleTree } from 'merkletreejs'

const SaleCount = 2;  //WLSaleの回数 0が1回目
const WLAddresAry = 
[

  "0x460e3196d694AC0628E62097457578439bC742d8",
  "0x41141606b4Af0938D957363A9FaD6Fc4A5e97Ee3",
  "0x7Eb34Ad2d0F39E985ED09D8eAC464db1987c7826",
  "0x4Ce51795aa1B388760F46E0160DbaEBE13E01357",
  "0x46eB6c6b847A67aFa48Fc8968F600D2ca4Fe4894",
  "0xee1a635c4803480B469343008D7892067df4694a",
  "0x220188Ed56866738D3DC3E218BB106594235FCF9",
  "0x5ba35D07feca20A0d38D25AC6f33a729E23C7276",
  "0x03043d97198F7C4966C78FfDE2243e9Be4A439EF",
  "0x39a3e7812F7d5851fc557C550E08Fa75700941E1",
  "0xAC39c8508CfB754eB41B605318Bb9f3513F71617",
  "0xF970fF14c526FC1cB0076F7152BDe44aa8e9e37F",
  "0xEDFF8D513E617083b7e3Da1Cd597985e72ff746b",
  "0x2fd87ACfee01B5311fDD33a10866fFd14c4aE36B",
  "0x5FFe40b0bA90D9D9C6632bD6f08F9bBFb1EB17CD",
  "0x31fCAACBeCB30bFa8EbAaA0f032dB1827b38f504",
  "0x772Fac7150228159cb67fB68Bab746EFb9ab3510",
  "0xa0a7430D96233A6841fC89D4aBa24Bc58C33c7a5",
  "0x0006C7dc8E482A4E8007343D821B7932856cCdaa",
  "0xfB29409dD2c76157a6F8f7F06Fd51630AdAa1be9",
  "0x751445320A27b13885084B63e1de615474B6B28a",
  "0x1f5066F3a87c117075F57eb3E9839F3999977FDD",
  "0x2b6dA950452b0232ec790396b6084a7F1e1B699A",
  "0xFEa1F20F1cdC0A7f7cee8173fBBFa5259D068219",
  "0x3B878a7C526eb3d1DFF8f69B80C7603486116e78",
  "0x273cbD08D2B9c878b436707189774e1b29531f0C",
  "0x3357853eA5b662EcC563D1e49928752a3bd23faE",
  "0x1b1E22dac44ABFFE65056B56C462c7cC8bEF06D6",
  "0x3dd306254f70d50257d88a9a75874088e5ccd0db",
  "0xf4CF92A51E38aE223F5917102D1a0Bf26bc595d5",
  "0x8Ce4809c5FfD3cAa5bd1392BE86aE6dA784eF943",
  "0x820775822f977d0f580d379e0b5b6286fe3de11d",
  "0xdc92b5C3D6Fe636df9d51e9068f4578A51F2334e",
  "0xF819DBb23d66424c3D21EAc3f49eE422364E0D65",
  "0x95E8997739b37Db29B73f954a8fdA7076B448149",
  "0xc5bD35ceC17D8a03be9F40293DF764453C714dc0",
  "0x90C1185D8322eD3DaAbf44B09a40519C71e03AF5",
  "0x93Ed601631407d91451AE010f68AF381Ea6D558c",
  "0x97BA8847EB83138891ad70722Bc771b64a96FcBe",
  "0x03b1F90A201233531BcB00C23074D1bd1c6820F7",
  "0x9cbaa3c812643d29f3a0c461520be34744255365",
  "0x092fcF216095a4cB7aC616BD30b26C4141b39019",
  "0xC7a2174a7fB001401F8f133114E609D0E30ac35B",
  "0xB80B7509A632dF5d77D5583D55f4e6320D2A041b",
  "0xda69fb774131cdCE04E8f8EcE3c67b20815Bd71e",
  "0x481012D086E54Da069841a39711A160BeB8bF67b",
  "0x55368D3F201bE0A101300b346F06a47052bb66EC",
  "0x0a808aadb9bec15ebd64508c251ea316370164af",
  "0x1f76045A9E6010aFC2dF9Ea0b6045413940efECb",
  "0x826d434d6FB50a988a7823adc16Db3fd11fa29e9",
  "0x2F1794D81eAa6c933A5786090787d05e579211c7",
  "0x0B4372e936f4C9d86217488Ba39b043e77882C25",
  "0xe74d4D5B49352439FdF66C4C39248dFfFa039Cb1",
  "0x8C529a77713348E7Ef0eefFeFbe42Dcec8F90296",
  "0x02A0229e53A5427d74C7b31047Ca9Dd5CEA7265C",
  "0xc27E74f23ABdA23c658EF12eC7B92FF43EBF2B2b",
  "0xF185B303775958C93AcFFa1231A8d14b38c049ac",
  "0x3524c468ddcebeb0df08574635a389967d52fdb3",
  "0x47aaf8133f95c047326c08fa61a33188965dd6f1",
  "0x54e929b2b6bd204b13d4cea6ef0b6fc1d2b75d94",
  "0x730f8d8bacc94dd7457c967ddb9c36117b7399e0",
  "0x99dc62b11c0ab05cf17ac701929a903f219bf0f4",
  "0xa645e4d2a0380222d25fa13b25546eb2e98ff151",
  "0xf214c2b957fb06a9897d8b49d049b7519b19af82",
  "0x05a77e1ebd56edf389f15aa21bc4c7a67f277d68",
  "0x235dcc40078ae6aede7766000f38a962a83bcb13",
  "0x46c3f40f95e5784d37d5f718abae4c9d311f6332",
  "0x50b04b2dd16b091b92b43894cd8eecfdf6b04490",
  "0x6a9a187885cc6e60f06c07f7b9542d2e772b23a9",
  "0xc999e34971211f5d77b854d5aed4209c93f5a7db",
  "0x04748073cf0d89ec795ad10b5d3296de8f35dffc",
  "0x0b1f309fbd3d038576df6d205bc6c6c13ebbe3b6",
  "0x1650356bb608f35b4fd1a7dac29fa10f688735ac",
  "0x165ba22a6c429477a36741c8130a2e4b64583ab0",
  "0x188f72dafa2e6fdcfa155f3d450b00ce51cc691a",
  "0x1da057eccfafca2a8e458f5cfae67a1edfb3d199",
  "0x2099296f14173b27bbe49f9232d3ef95dfd1a259",
  "0x20c326785e1bd78cf502c22c0913a0f300c8159b",
  "0x2255729fe3bc094f751e95b8602ce1dffa512306",
  "0x2497aec945fe725e5234a0d3bb004da0b1fd965c",
  "0x25ecb907d847f9058e841aa00125064f3180837f",
  "0x26c88dda790f9f1cc11e02a076d589261f0fd949",
  "0x2c3ed0211d5ea74ce3da545b7af217e4284ea030",
  "0x2eda5fbe766f0b06e822fb87954e211cc4e8cb2c",
  "0x2f7618af8125f4161a2973c932c0cf5e1033160a",
  "0x32f2a7a720e7d1f8a4044ee358ae56fe4ac3e756",
  "0x4da83a3411c2057829e48cee4969289171d5633a",
  "0x510ca34aa2f8af526d9d643fe662c85a7b8e9c05",
  "0x5243bde21b1bfa9bae695b3dd1e6346aed8d4a30",
  "0x54234c39e8bf1330658d4e83da2e28ad935ea7c8",
  "0x587cbde67de3de091361bf5bea1a87f0883a96c2",
  "0x596207c2b35b8c6cdc1c06b8a9419bff5126268d",
  "0x5c74bf9429429ee624623843f19e3ce689b93f7a",
  "0x5d74506dc78877d64cfc653198c8d85197b760f4",
  "0x6eaf2eac58c2e637cc961cdbfc09d6962a4e6352",
  "0x79082205e8e280baa7ce98c44d44e82b3465448b",
  "0x7e5dc034721ea633f71d99663e7ae2c431803eb4",
  "0x83b62fd357a6d3e6e34d74bcb89f0f0efae4d171",
  "0x8b9a364581edd2520d355002d7049568ac64b71c",
  "0x90e0d0515558a36b13cf5b8b648611d74cb0404b",
  "0x9f1777e11acb782b2b7307f7e4bd59874a230e7e",
  "0xa1c2fd912d32eba38599cedd385cc61c72b48fd5",
  "0xb00f7ec7313c9cca6c78248175cfb3b3848e2742",
  "0xb189300b3c53fa1a4cfeff9bb49e6f4e5e5dcc80",
  "0xb2f8e8bf06a3da424f5c06e493af63e2ef10fc4c",
  "0xb38d081dd6c8cee8908a9945b994554a771d40e6",
  "0xb6e458e460be970aa5ca3fea1857e28ad3874619",
  "0xc15c26754eee66a359b8cf913367c6a3d007d77e",
  "0xc1dcf235b192bdbb2b7b0aee4a2dd360df2f22ce",
  "0xc581e15c93fab537bfbcb148f649ee3d0e60fb1c",
  "0xc64a0e3b095ab0271c8e24ed18917f435cda90b5",
  "0xcb509cbeddc3cf71f5a2133435eb429a571e5ead",
  "0xcea79c5faf70662e934fdfec1fe5da4045635841",
  "0xdd5a5db464eff262bca115936b8f97c00d451702",
  "0xe0de8d2700813c0fd008a684c5586d0265ebb7e3",
  "0xe6394c637f94130c7bff7733e93746675a9fcc9c",
  "0xe822fec0272e08b8d52c8ea5ae7959f292485975",
  "0xe9b1e0fdd904d9840c10473d28dfd2d97ed5a5cf",
  "0xed9b2d684c3144caa12a67c30700a5e7e8a889c9",
  "0xedd27a2e9ac8358411ffd91cb0f0947ca59fbdee",
  "0xeff1d3bac93eaf9fba11843da31d750bd12c1ee7",
  "0xeffc70d77408d5034a42418424f0b364bf8ab3bf",
  "0xf53eb8f8d56289dc8d80ee8d51890ee950eb65b4",
  "0xf8cc12826c685ee69c37e78f8b74a95f4a4e0c36",
  "0xee7e24fb774aeb2890982158ec03db69513bf2e3",
  "0x73525bb0da5f6db0119d92e7533d396e60e42884",
  "0x00999a5d4cbba039b560263fda3820c4e5ed5fec",
  "0x19c4a604459f06151043b9688f172885bfe6ce19",
  "0x41ce1481e7a417b882e39d9a842e85af0b5c3378",
  "0x576a1a3b08202e31d8e9e2befc214891399c674e",
  "0x41141606b4af0938d957363a9fad6fc4a5e97ee3",
  "0x842ce79e791fb9a2bc8cd6b1904d591bf6e59a21",
  "0x64bb0c21d3ff706886c8803f95651d3d8ea8f5f2",
  "0x9f38d150384cc6cb4d95b40ac8b0d4207e164548",
  "0xd37864abf529d2a65e4ed68de9b3059b68e43fb8",
  "0xDb6FfD703EB4c187Afa628757C266a0B28511C82",
  "0x071861a4596a9D4A85fdf231D953803358E675d5",
  "0x88FA40d34a13aB0bA7dbaF1a483d2f71ac20B620",
  "0xCCf17cdcDdb9E8d97E658eAde440983f34983b44",
  "0x9bF62c23BC564D6a11AC496a2c75FE96e11f22AF",
  "0x6E573fDDeF5571F9D045f5EcC3dfb0bb3fd18D74",
  "0x482283df5A69Ea705Dc609e628b4F0CF46428149",
  "0x0B241503f6439344023BCc68468D7C3fc9023307",
  "0xcA2734b05CCb406a630EB6ee4D3b0ff464530266",
  "0x91D936d9b360599d32F9B42ce01694Ad0153E663",
  "0x95420DEA8D55F34F416cE849a7dcDB20f48B8624",
  "0x6cd8615afd527fbe0398afe0d802300f025fec83",
  "0xEcdE48F89E1DcDe85fFf0d3719a8e66913937a25",
  "0x09A3F0858E120183ABd02a5eC50e271aDbBedB05",
  "0x60fd9959ebcc58A0d25fDFB0D54d4E44D099960b",
  "0xc403D6752f0987502aAA1a2Aa8275C803BCC6521",
  "0x3C2Be7d6d140b831798F0FAab88FBE1F15087186",
  "0xC20F4871243ec100Eed5d4101F1e168506482d0d",
  "0x057CeF93A0C0917e3bBA1a036fa4e348aA93dc21",
  "0x1b7A334c0d56b34c70523D49774e8b621DC2bAf6",
  "0x907D73Ef7BF952d435641aB901DA719A8C1d3E78",
  "0xCdAF9c60dE6E99CEa2d8745Bf5Ea60ad1d9D5CA8",
  "0x8919127468EA024D575e0E19Af23Ad009A435466",
  "0xD30d6ef5AC03dc6539bb0bFdbc1355564dA6ffb7",
  "0xc7649c5451ab3B8222e40e261fbee3EeACDbfF43",
  "0xfc467b5faac02872e242e75871a72bb9aed3d143",
  "0x577A010075c602252c760006f3f1b8305c469df9",
  "0xf8b02593e168139873F1c9653a5D08519b06BCA0",
  "0xB42E4920A5b4A0B7249cB7AEdF02c28F18df8BC6",
  "0x5C661b0211d79AFA533AF8Bd94C2c153834B0E45",
  "0xCE808c20E3211b1Ead1FcE1b9c2623FC47985881",
  "0x09B03731610C4Ed8e697ab65859e2E6A6ed2A031",
  "0x9Bf85B9fe34CFD4D216c2809f020B6cBc819B0c1",
  "0xBcda14444Aa860f0C673e5d73a18f0bd870Ee343",
  "0x2efD1ce75672657EA387eFe7d7f639Bc6902C313",
  "0xcb2A06c2F4B0229E3683282bF35c747B64DCe36D",
  "0x2E6DEC815B18b9942322D99a3460C8B82490E566",
  "0xcA8c44978f709d21e805086c19A309DC694647B6",
  "0xaD29F6dD5a03105813Ad0d879383f818c6B5FB99",
  "0xA0c14a2271b2cD035E6411904d3b72b1bC576549",
  "0x065dB1f1027F64e5239494650c9d472440480Fb2",
  "0x26418F6c7dd2b6bFE11C7A6A44B2353004725c5a",
  "0xE440964985D8005A5f44Ed830Fb3acfceF15dd04",
  "0xdA8Eca8F379A52917d8074ab9ce40b8968E68af8",
  "0x1B997268Fd06125d998B959A7d29EAD5EE848FD9",
  "0xa5f6a47C1ad9C50deF3f695A0b81dB6975315A30",
  "0xb778D21619f66F4d1eD2F3eBF3dE38F773571BE9",
  "0x0eb8E5E8723f4BA8ce19025FCdA41d2E330cfdc5",
  "0x32A8dD3Ec2D98D33b480803673405EC99ebfaE63",
  "0x0d0EE69Fe655BeE6252E544dA4cE3C93c76c7099",
  "0x8B45F3e760915b71Fb9833D03B5671Eb64509b4D",
  "0x24aae0A135985Dc8aE6B8BaC64051aF645C2b3f9",
  "0xB6985bCb55bD76Cdc5e51620Aa7Cb492513C9662",
  "0x9ab3D0b72E1e300b2c44b1Cb952b5a1EeF30ddEA",
  "0x62DE714a9F7978D2a355151acE15aA546066B5ff",
  "0x5A5116E05458DCC704f1694589354ED4afBAd77A",
  "0xa98027e0066471b60A30F8F02A22811c440734A5",
  "0x345C33bBC9E5B7BfC62378EE77F122cFf2404B3C",
  "0xd99694fD205Cc2c9d5EBFFc5fD5ca5cb5416Ed03",
  "0xB3de53A1955D327a91CDaa0fBcb82A27231cD1C0",
  "0x603Bddc4d7FE9681dcfbbDBD99c0B152d684bA45",
  "0x719949c994850c7F9029b4aC1517D7721556EC4e",
  "0xE1Caf69BDD6Efb3dBd1fa016506e40eD302512c1",
  "0xD1d46a6f0c08caf3577451F02009D392EA16D574",
  "0x63F447FAD1f87253ed6D32edFBd74dDa357A3d6B",
  "0xEAbB8945bf334c05144A13DF75eB76d047a7eebD",
  "0xFdAb2E988Cf8242AfbDf208f9368868d050CC253",
  "0x2722AE324b9f1588ad05F820c19607c5C7675F51",
  "0x5A4E0cCA1432457d5E86d9C8660EaB2699e13679",
  "0x84D021B6328B1031012DF3ae7D7f7A59F715330B",
  "0x71D23d9E4e234fa47BCF95F5324C9BF304ED74dD",
  "0xD5b7c85b4e7e23F4e8C21B0D3Da570D8B13C9cbf",
  "0xBFbA0CE7788ae4913d0A11c1aD648b5d7A5C7678",
  "0x9F51Ebd5b5032a964bF5e07198923BA6f3aD150b",
  "0x84da6E1581bb33634d7cd700fCA82dd246869bf0",
  "0x56bf211663c2706a0015e2BdDeA5293A6Abfd25B",
  "0x510E8C464df9376E8E0Dda1a16F6bF3ca6c580aC",
  "0x0911EA731E70eA98F30a5b41894EBC256DC97Aaa",
  "0x3baf2332c71b18374d7F20d7D0c58C2baF87F447",
  "0x2f79058836380b4DF25d4fC6162AF7101eE11A92",
  "0x0b1cA8a6a7F042F066bC0541F7B4BDE8194F435e",
  "0x57C8C35da43B7Dcd2486A6ab0E91A4Fb35b55fDa",
  "0x6D5618388DC917134Abbdcad7AE4071AF9cB5A7D",
  "0x99dc62b11c0aB05Cf17ac701929A903F219BF0F4",
  "0x14aAeeeB50EcdA645221ac60e3a15E668818660E",
  "0xF78871916cFa6F1887FB5e75563C541413Ea0B95",
  "0x68835b1374fEADf7AB1abAbE1E30901eB1A142ac",
  "0x842b45e5D1Fe63cCF9Dc0948EE0EB77f9201bf10",
  "0xC41cbC589134860EA07a476f4Ef8767E386f7B85",
  "0xBe04e3492ac9C537b489331ffb0C9b73E3e15234",
  "0x22d4ec672e14c5abd785453e3d95f8d430bf3345",
  "0x1Fb2DF535d1c7969a2964F49E25cE3a05bf45A91",
  "0x3170Df62b9c8CDAAE487adE87c2d82F87073864B",
  "0x280405481b1beaDBb94846b57f23005676eF1211",
  "0x4942EB4a6185Cd568e65Ed42202750c2F09A8166",
  "0x6d6191789d19332a89d8ddf311e6b0d278c9b0b1",
  "0xf0a0102e206989d402054bbdf43792a1f55f8c98",
  "0x49eF397534F6299e0A8FeE75Bc16F09726E11ef2",
  "0x34a6Fd973Fa46175F8eC985d56a396Fc7ed726FA",
  "0xbea72d78bfe1d9c7f10adbfd13f0476101cd00df",
  "0x2368BE6Ac14157B0E8EE13024029B8521483eF0e",
  "0xB43de29A7087FDaf9143E72B2aB093f935057B08",
  "0x5bcFAc3bD7eC4349774E8d9909A0E6b40A0E7CD2",
  "0x31c3DDCEDD318Ca19bdD878bc08FAb936288Fb10",
  "0x6f58173e366C3Af7e9d14067A80166F673976B7e",
  "0xA81BB61F144fDb52fF7020aF929c2A4Ef1C8D723",
  "0xd287682f24cEBeB2eee2dfE5569047d6F6F03E5e",
  "0x6A9b7c2Cc8074505A535727B2fE6c9b0F67D1013",
  "0x4583CcDCD5EEC952C730b3F6E68ECAa531fa0F58",
  "0xCAF17414448CC1574A5d2026D82D122439E91d33",
  "0xeFb31A8EFA0D5d898ABD7d3586D6Bf5d02d86d2f",
  "0x50c2DD1aAF0bC311f700f1EB76Dad6C32aC06c6F",
  "0xC7d907517F810c55eAFaF0ca3bA5136b3D1eEeF6",
  "0xF667170012cAF7EC18baef67B08072970e5131B8",
  "0x4B52AD066877867A162FAB6BE346ED5f92030A77",
  "0xd178De65d332E00d2b7bcde44f08B5997e00eCbF",
  "0xa092C5e23e3AA160D422a815D1Be6C0a1F3443bd",
  "0x9e54B404Ef4aa5c4ca5c5bDfD7013637D1bE4C77",
  "0xFB0f5755DC920416b486752423c572451af72AEd",
  "0x3E1dc89ab1E3BB8A64bB0f65b65b404f1BF708c3",
  "0xEeccFA44CbC4dD0806fbB1D933acaC4c0611261A",
  "0x4f0e7da99223ea377595b329be3ffc732f33df9f",
  "0xF14ca93df47b825C4a83912e7775a4059Fb6B283",
  "0x3De3Ee836450813dB0E7959D19e90725816418ee",
  "0x74F27a08302b9263017f47fa5058D2d68A7c9e81",
  "0xf15b2D971B9b320d931B5264Df47af3B4DB82981",
  "0xE62c8334c233D9ffCd968095217f86E25d4eaF8C",
  "0xed18AE6881D9b45a22D0F910edd33cf19Fd41bdB",
  "0x27f5d0be09147ea88a2033086cc2ff177cfb6604",
  "0xA64CcA30B8D439B441196e7a9c2Fc66f19D45524",
  "0xac6c198f53b2fb45bf6026c293ae27600e6cb9e4",
  "0x3d082CC57f2D7B6FF613d570a7597E085b1D923b",
  "0xF930D87d184A58E5906D0CF21393C64f86990945",
  "0xB8D52F8a549548D40Ad7ACbf224973F5aeC8D17E",
  "0xfc5F6FfA7007900654fA9E974Be837b26abf0cA2",
  "0x216EaaFd99c9eDBe7ad61691AD07A5d673600587",
  "0x3ba5De9aABFA647Ec48B7344E0A917256583f99c",
  "0xA77a4CcfD8Dba7735C45F66bc7Cd5082A9E731B8",
  "0x50252dDeC36De574734FE0CB4A475c7e6F1C2eb2",
  "0xCc3d51c42d1a6352E8211FfbE2576783B5B47d98",
  "0x2ccb8e4e82127f70db04cd5c3c20131931f289e5",
  "0x19024DfC106E6111f80225A491b045Aa6D8B9941",
  "0x230d4B576467d7Ed7d918393f52207Eb66537B2C",
  "0x7d442652EEd3De3a8F2aF857e652BD303e57b317",
  "0x0F45BABD9Fb3Da84a3547744292eddCcF849dD8b",
  "0x5534f6F9D06cE2b2b34973834E8Fa15F1545fAC7",
  "0x46eE5d9395B6e74695D3a11036E6708b530Dc07D",
  "0xec6001d3a7fEb39FF3eB59B2F12372eca1c9EBd5",
  "0x7E35165A4271277599E717768c66E33Dd89fFEC0",
  "0xeE33aD96205462E87736FfaC64eE26Bc4D6b0FF9",
  "0xD04dA77f039d200fd15d6344dAC6b3c04655e880",
  "0x60801EDd0fd3f9844f28F6587757DF30F12c3d9D",
  "0xf5d1bc6E4d753fDeCf83fBe443Ec2796239a85EF",
  "0x0606aB8198aacB9c7d7343fB73E73B9B70281B18",
  "0xa6De3e0894f460262C2CE480292ada240E25D592",
  "0x058C0837Fb875ca021D9f1258Bb4F4CF15E682B1",
  "0x7a23883B26973eb32AE3CA6CCB29513421649A5E",
  "0x180e55c857ff5d2847e35498636c8000a5620a38",
  "0xaf72d4f84fCF9Bc35BC9c163A3efd7380EC6adc0",
  "0xB3AE6014383ADC377dD7a6cb8cffb8eb4028E203",
  "0x07059126073803a9E3968C9279e76a4D73650037",
  "0x176FfF8b046f5B89EA328e3A6a79D6fe88905A0e",
  "0xb963eB1181B61c03d6a0E6C9660C8bc31AAf218A",
  "0xc473CCAE0baE4bD973465904BC9C3aE8a181178c",
  "0x417487275239E0f6c5AC79f5D60135eCf118169b",
  "0xa3c4E655fDf728114b1451b228e5CB2774501449",
  "0x7dEaFaE6d99b5b072B715BA34aD948CE13c28945",
  "0xF06A576969A806E3B822AEC9749a8113C6b33C67",
  "0x501790C6890dFA43c264AeE4Ed9aA5E116d0A0d4",
  "0x5Bc3911BEAc22F1903fa8eA1D9126eB014E73cc8",
  "0xd6864ed11691fff731f159f94896f47a6aba2142",
  "0x1b6d6bBB64F183A8734F199EE1168189BEEfA354",
  "0x224F151bD65eA89938F7c6aeAd83bfE4Cf701c3f",
  "0x52EAf342132696563bf4bda8171f586AB2679d5f",
  "0xB2F3F1aA397EB140c011e6C37198C5C283f8a6C0",
  "0x2019A19510C8C50dB97741A2943400a789f10219",
  "0x0c7d841966696af4d1aff9a56424ab938626a443",
  "0xF8537590ffcb32968A60EC6397d8020De32a3956",
  "0x6d92DE957D5a4Cb0BDa556DA42962e788aE34557",
  "0xcE2d4fB47140fAd9C346e1C34d5AF8fE309b8dA1",
  "0x9F5BA9f69CeEe0857e2e2E3dCaAb1c361dA5cb4C",
  "0x2c4a66EaFa647c9F549640001AC31865155bF2ea",
  "0xCBacF66882fd46Dd88D11B377d5795968482602e",
  "0x905472Fcc4f835f678aA275B35e9E6744Da4dB0a",
  "0x936Dd3921250F6f5A9bE25331355149750296A73",
  "0xef212e729bC6a5054988017Fd9515103d240AC76",
  "0xcC170fF1b1fFB96f943145314aC4A22B1563e02f",
  "0x831bD61e37e7407F9fF0968A7145BD918a1010B1",
  "0x53f54C259b5Bf0294f6130ebC2bc1EF7360fd8D1",
  "0xd68C9023960bBbf1C5b9BA85BabDE0080A1e1aDB",
  "0x663984b03020531c42a0d46EFBD4A5E67eF12Eb0",
  "0x49C521807922878a0B2FBE082F4e4c5EAB475Acf",
  "0x175b60fFB51815A1177DB9706059be8B3CC6c1ab",
  "0x4D2f8962b67acf15c63e6688E1804BfD96fC0362",
  "0x33fe4A6B3E79615067E75bDa042F8820D7666d82",
  "0xf406aed3369614123eB6c8ea6afB12995D49dBdB",
  "0x05d3f97C72909e85092856b4E4e63d8e17C7a5D3",
  "0x54b6D7337085A90699Dba4fEe76f9Fb291625226",
  "0xBe7B6e82bf3ED316FDB517d3478b0D280eF01B02",
  "0xB3EFD6109834AFd4A2aa103fd9b5DB1D8114E2B2",
  "0xd1A34fCc9bCF1f60c00730E395627eABe10B8d98",
  "0x658F5d6452519dedDBAC7Bca33f4E710e4dde37A",
  "0xc4a0a47e18358BFf1267ce42e7Bbfa4Adb367387",
  "0x20Ef8d8Ea210ec7D74dA03f753B2E63c39f230E4",
  "0xED8501a2d9E65ecdac99B63b82c9b168FADaCCA4",
  "0x07246c91dBF58dd091821070DD8D06Cc4e0289Bc",
  "0x9267e546A4db3005983927dBde53eEf6346CDe1E",
  "0x82305A9EFCfc89db35f10B7De3196Cc663De5e09",
  "0x5E37A4f9Fc7a39BE9990013362c164C39bA4dd75",
  "0xe0be3820ac3cc40e0650867d0f387ee113504dc2",
  "0x07820f143E56e6191D25304FB4df3E698e15c1Fb",
  "0xF4d18C9f310a25A49D9F4C94fcf5B21Be5c44554",
  "0x54F022924D6d8dE34EF447CaFbc875b703b233Dc",
  "0x11E8D6a5A886232715cBdbA92e427A875db8dcbC",
  "0xB4Be66d03D47b3D59493a83173bFc4eb20Ed6c14",
  "0x2269006328Ad4B46f36159b25e1438564e5Eefe6",
  "0x03E64Fc0029A53f10c5ddBbFf9e01350e0305E6C",
  "0xe25fe4213821705c3b7b5251089E57cc889f0af4",
  "0x396cD5ab7f25e5C6641FA196edBbEE4730D5951b",
  "0x6CCbd9005dBD29988c56D83ce8613Af022C8F3e2",
  "0x50a9e92F1311a07585E189ce7e5c7da35adeEFb1",
  "0xdC1992FEBbD3121CcCc36de1FCF9e432BB1978e7",
  "0x0880876F46A8917f4d9237BbfD4900648580Bc83",
  "0x9e7159DeC1C84B3e9345D4a0DBb098DfE4eBf3E6",
  "0xE4d6A7df1E6C07Bb96e632e15345083C2a7E96A9",
  "0x32982fFD3ADCA59F80984068c6556e01958e0218",
  "0x7d3d8e04Ce52346a950fA9A6C4583423653567C3",
  "0x971740Ed368934875f1890898D5FFC10EA99FA43",
  "0x493Da0cDe8cbe456d096D296B88550a703939354",
  "0xc55a6f3C0B0782eb60B9145121293B0BaA80A22c",
  "0x61C5601a4F1bD725BACd904764c0822c1f581337",
  "0x5e01fcB1C4b9Ad989d4E46461569EdB11606ff16",
  "0x9215EB059400942e64f9aE1E87FA8beAbA277aAf",
  "0xC8e7453dF8184dD96991d27F5293d14B49FD738B",
  "0x222e1e46e5e04653fC96aA1D4f36324f99AAD658",
  "0xDf1eC635b619057AaF6Db23A221963d5D258f573",
  "0x39acA781608E1c68e52800900C34EEc7b9c20cB6",
  "0x209b4B80Cded4eD6667705966B45f594504808c4",
  "0x76F25106a1BA936858DB86575B62E03C920dee1d",
  "0x0314CBA13b1db8BF3F5fB26244380A76E5d8fEDa",
  "0xA62Ef7AB9F3fD15bCCd32C9FC6D2321f5A46eC0A",
  "0x30DE09EB48b128cECAe4549Fd32D5019B6664158",
  "0x41c9b829a3C9DaA6962Feb5c4Dc8d92ec8BD8CDC",
  "0xAf68905Bc0138B0CFefcda941Dad4402A8af1242",
  "0x6e865296C5F4c6979Bc26420f5C59ebc0Cb1d964",
  "0xf7355577F46BD0CEE842ecD9bf69f60F5629d88E",
  "0xcf048F5CB5aB5D883C60c3ac45FaA8eE9B90548B",
  "0xeE80EA1edC597c6A5C4Ef03830366e39b6E3603B",
  "0x344d757844c4bD89BC8B28FE0bF112aE5DCeB960",
  "0x3c1ddd393857fe177a1208840217b3acdf94a549",
  "0x3307f7347c097dca8ace820a279cad2cdc2e8a25",
  "0xd1CaDcE49Ae6F7dC57603191C044EC30413af3bd",
  "0x8D7E6A8735496E95Fa63BCf09967D9cc01c7FC9B",
  "0x0811F494Be389c64150e8F03c93EC0C3d5245A8D",
  "0x29e1446d062a51bc10733a1f26FDd6b40F2f149E",
  "0xC82934238d95E46722dCBCfc07BBc1880Bdfd534",
  "0x8Dc0b38A29f22544529578A1C3196B9d1d719CE6",
  "0x6f6514Ba9Ef17C60D16bef88968b063D35A8c4bD",
  "0xd8a21Be32FEBE7030ff3D881d7CA4770Ce3E6d35",
  "0x41089B971D22ed2be9E0Dabb7Da3b5A702B737f5",
  "0x0f05a63Ca100D4A3Dc57e0ef334656Fdf6340e5d",
  "0xF20F790346A218305F878E6625e06B47FcA5C4B1",
  "0x0c5d3ED0b44b153081175d2dFfC9A6fC6BCBa926",
  "0xD49b0b81b8DfF16ADd924871F76923fA3Bc65B30",
  "0x8dAaf6d8207c9377a095CE0D4d00263aa8489583",
  "0x0854d9b13F7C9071d8D9CB01DAF927E4fEfe8d99",
  "0xD6c8422839b7965DadE45349F6bCea6b2963B480",
  "0xEc2ef0657495611906bcb32dd98f6b41f50186E5",
  "0x05D3c535653f051eF178ED05ddB8bf3b9FEFB1c0",
  "0x6f3809475d088afa5da73AaF5bFCE6fc846f361E",
  "0x8C68A303D725afeF6DEeAEea04F37aeF808EFC1b",
  "0x8f318fD3cB537B3290D8994a7A9B0fB684D0ED35",
  "0xE91CA2AC1cBE22717d9c532a702951CD72bF5409",
  "0x726975b524e28f3657a5AB5B1240C30534afF4f9",
  "0x868C7C37841B426379164cFe994E3C66F2aD00Eb",
  "0xb812d66FB8715539cd4C1d0eF3bc237F37ED5001",
  "0xd7c1c093c17891b262DFc9B26D40bFA29DafF1dE",
  "0x2CE91B6F9435861B32ab2FE3641CF5C21decb8e4",
  "0xb1da7C873d50AE271a153693ECAD55b2262732Ee",
  "0x433d7CD5128FeC7921Bf71e3c93946ED6F2Fd3dA",
  "0x80f149e548b44E3b7dBB9F46B51Acff53e3a4655",
  "0xec8287FaeF6cD7fc9887BE6e40D6ABC0Ccb29D2f",
  "0xff53EA4E24cF577339f0d4036a82959D9C575543",
  "0xb4eb800b80751B33e7ee99E7365997f4b6c04435",
  "0xb17dcb47AFbD56D1395afbE684536796AF1877C3",
  "0xF5101fBd40CbcCeB1d8A70D0aB8be35F5DF55eF1",
  "0x90190c9dFbFCEeCcE925934A3D1A50384aaBa2Af",
  "0x046AFdBd9aAED371607606d66aBAbbF58fcc9Ade",
  "0xdE5c68eBb8EE5Fb4684f16c585A6643C7A2F3C5B",
  "0x1225a810e7bac6e7ebd0954e40e5c5b9fd62816a",
  "0xF0669Ef23b776Bb5998920647996BB3A137CA52A",
  "0xA0EAb382c09B38dB4c21A0DbFa9282ff955ACd52",
  "0xad505e847C671418393B77D6813Bc9BCB8B831Ea",
  "0xB8B8ea6297b95025D76A4154Ad72EF31CbB46713",
  "0xa42228659EBE6f4109A94872B1882d49FCFAd9f1",
  "0x46AE51E407995773A845d22849400ADb45381819",
  "0x5c8A037baafE567a0fD1Cd45D55361c7A022548C",
  "0x33E2bE6F077810C39F2EC898964870eefE3E634A",
  "0x50889e629ea47888a38b0b256bb0212183b6a1e9",
  "0x6e81F18d77d35A39C4cd41220b78B2991b349f72",
  "0x2937E5e85fcd38FF36DDAB3f1A0173375630AD02",
  "0x51bf3B63Bd445e25d5a00Add7aF3e6FCde43b0c3",
  "0x1F0DB3F91aA03958554bc0641c8a1b62b2217e08",
  "0xFc94717008dA1301956f9e8e15dc9F679960ed17",
  "0xdA1aF0b3d9b4DB3F8B07524DF0b521E0Dd8f27A5",
  "0x889D27845a282337bAb4E9Cd813e4810299D4559",
  "0x764b2Df7351B7999857d605063C6d48cC9713252",
  "0xB4Ff09f7b8b3ddc9aB552cAbEB07Fd48472BBf52",
  "0xACf72253777928aBEb60d2100AF68Bd5B0703f44",
  "0x45d8c315d8C32ae4b1a14c954c623CC5741e791f",
  "0xA1a45a2B5fCdcdC590F5203D194e563aE6742E32",
  "0x497f66E153614510c6a8b72d491518b23c1CE326",
  "0x92253B62Ee21E12814b5Cb4Cb2f04C012521cB5B",
  "0x9f88dC762c508acFB6B347605F5CAc321c64CFf7",
  "0xA73757C41995E93A0af5AAe7828AE3369752d09E",
  "0xab635a4816DB1ad6CFCC9BEcef1738748770EC8e",
  "0x6fF7889736467376F53c3836c38C2D62ac0452A8",
  "0x83f1da84cFF2dfA46E0b485268fD2C1d75fb0F8f",
  "0x4AFC6fAFbe1cd2B4c074107b86d2FB95C4c76abF",
  "0xDC0DEf6B5bb6E746792B8d1f18107d3fa62068D3",
  "0xf7369b03E9a3ffc9a94e86cCEDC3cfB1B8f1c7bA",
  "0x28232DBEdf6fA6867548d4A5C199902e2bebEA7F",
  "0xA17cC03654E43576fD890c3AEEb3670FB70DD540",
  "0x7Ea4b66D590fD0A36AEe576727630f0686eF99a1",
  "0xE7da07A22a3Ce8Ff79d1Bd19ddc797F744b54400",
  "0xCF158FAC317e13c6a3E112D0E9F719004374D4ee",
  "0xadaa8aFa703B63d86baeD012FeD48f7781480565",
  "0x839f0829954979FA4e0ACcCf211319A1E860904f",
  "0x263887a66f331C694cBC75AEdBFe902f8b3AeCdd",
  "0xE5cEDFb5621e0EaE721813854298B38FaC7C3612",
  "0x8B99fefC7f57E45360752Eeab39301d9d6309b93",
  "0xfE5183705D5716B254dD104e9DfbAe473e0e3a56",
  "0x2De227eF7Cd933D74B2072Fdb1a8bA4B047CCb91",
  "0xDA8265f2240E5AFdE835D098d2230A8F0600b60f",
  "0x7Ad0a3FF7c76A57C8c5e05d08379800FE1311c7D",
  "0xf249D7e1C81e002a7524f8fd6ABE6da3A6ebfEce",
  "0x0De4157F9b3a35b1C0ec3FCf004e5461E94f9809",
  "0xF604d5aB7eD07173e3a7402b0f48796A9c8358cf",
  "0x019aB018dC731B636765620094d8B97099468A11",
  "0x012D44470f3C5AC6037908980948503bC0089Ea7",
  "0x88577Dc76652C02Ce489139EB66a0985Ad44b325",
  "0xcbe2251c1e34c507bd1ec29e4c9b377476a40098",
  "0x7fc061A7d3884fcd52e08267Dff1C55Eb56a17f2",
  "0x58Fe2163A2A538D12A9bA95BBa46ae078a4703EB",
  "0x43C9a7e362c6aD43896E962CEC9A3096302B154E",
  "0x4deB3604acAc0518703009dC9dA2310218A7d9E3",
  "0xBD080Cd31064aEc4BD3AADd788f0067adE8d1843",
  "0x6BB1F361243beBc0F4C5Cc853c610F4Bf0b9B39E",
  "0xfcd49Aa97b91C3575d36C95a1FE97dabeC017890",
  "0xB6a4995a28a9E9eEd13027F96E481bFE1f01c651",
  "0x2178038812AfD992c18210760F4defFa9EcE6dd9",
  "0x895F25a04C7C4F0Ce96BDE5a83dBFBa13982c09c",
  "0x990d97AC72Cf56b878314C6D3CB1D53FBaC90264",
  "0xF9eB733e5315f2757De8F827bB615a0BA6155823",
  "0x0f986fad391814E50a0C088231c11C510eF63c85",
  "0x1E517F09814De569f5aEa73468eF0bB6c34070b9",
  "0x4ca6A687De4eF96d8A1B7AC33075DC344c7001E5",
  "0x6d6acfBE13107C7BC8Aedc5F633779Be50A7C8c6",
  "0x7e59a5610F72A3aA51e5e2B11DCaf212C2b0Df7D",
  "0x38Cb24944A2Eb0A3B4B2a3F91415F0985d42907C",
  "0xc9D791e3D79e667bB1Df86cfB3fD98D61a3881F0",
  "0xaD30e3b5064866041222E32f3602d0396fcC1868",
  "0xAd0C0e1ac3d0F3612Cc7fE9945A9989D5bB0daD7",
  "0xfE2AAFae0f99D7735410818773dEacDA17bf7919",
  "0x48156b2593B4C271436097eb6030DB2451A1136B",
  "0x685Fc609eeE12c5F312F8889274A79Eb7eC85ED0",
  "0xE5E29bE0B70d26BB3422a886FC886cdC846bCF53",
  "0xA37f05806587eaEE70C23173E108277C50c0b28a",
  "0x73DcAE90AeFc927DF065146823be7EA88157aA16",
  "0x9d8a594deD62730C83815076Db03953ffd8a73df",
  "0xBe936D42c17AeB75901B944E73D0fda25CFcD50e",
  "0x89794f66FfA4677e9A9da489b6Ae333427007C1c",
  "0xD074D8a2d7D24D4bCb66A9d919a35cBD2448A16e",
  "0xedA72ED3e4EcC075dD59214F2Dc349493a0d2135",
  "0xA46666810794069Cc6eE3CEDb1AA62904dB24553",
  "0x568ae192fB77BfFCa00Ed5DE41d7C49A8b140E6c",
  "0x9245580c7A1c37EB925e93f67AD044145a83B04e",
  "0xA93A428b176174AdCb8C7abDA6f7F8aa9B597CD5",
  "0xe0E5551d40cdcB16Dc304f4D84858270610D4C26",
  "0x470027d8DC30eF5f4F01c79daB20ad2068859D27",
  "0xb9aE6023E9D72B5C3A36c08dBDf877b8f7570Fb5",
  "0x37e324E9c3B7C50F4C4431eCAa1C96C17b432729",
  "0x7758B2c138665eE64CfC4BA28E86FD2D8EEf1967",
  "0x2A84c441f6002b2310D4925232676E6Dc8E78A40",
  "0xeEBEA0044D9Fb26328141e909a361de20958F3c2",
  "0x7ba28CD4EA01f055C5a32F6053EF7a9d8F2A4FA7",
  "0x662F93891C75F6E1886B82620c2f9ac916C96b62",
  "0x8ebaF3F8481a00c8a98e30113B4f3b0cFC94D606",
  "0x5249E65b3A162A337Af1A6b0Af0653FC51aC70D3",
  "0x626bd1f30a5fA2cb500101d0E92bB34b5a8A5958",
  "0x51a3aE53B422b6bF23393fA4C73081bD25d7F591",
  "0xfA10abC4f444405784E90b50A7601851F5030F89",
  "0x6F4c1b15370Ee600d5C591D93AA208a57Bf54396",
  "0xA7D72b48C609C36eDf6EaFe767DaEB7B5248d5B3",
  "0x6a12da09D6C06210f110eB9B71152D73A140f52c",
  "0xeC7162c22EAc8e6e2aADc7e91F35546C90a53Dc8",
  "0x3F5Ca2A1eEFa826B10FAc0491E7108Dbd5229A7B",
  "0x369677D4c874a8026baB996720bFe476e0b0d0D1",
  "0xBfBCf01Fd823990C96FD7d299B63D1d890f28b87",
  "0x36949f79f97fb1bec49a81Fe7c9FeD491827e2a6",
  "0xE45D2D340ED8C6Fa98A4E34fEc137CC9e614c9b1",
  "0xDf063cc585eb21Bf7F1EFcbA408f7A6aF4dC38a2",
  "0x4213AbFB80B984DD6bE3C52690D44CBc020EEeed",
  "0x73f7Fd9060D3836361E8d9b1fb317E7e0da4cc61",
  "0xCe6E1Ff61f7b0B82F5A22b48891eE364b46e7CEC",
  "0xb7296d6212b20bB8188f47524275cD03e473715f",
  "0xE785fa02E1F51Ba7dd3De226D0E4309CDaD2c27A",
  "0x75C4D7544dC881596e3679345A7E915f62171DeA",
  "0xadad7ec70ac6b2599360efc99089b575e4bc376d",
  "0x5F6ee3220F70CFA2fe974aaA8feA15305Ed62e7a",
  "0xc982ac7167Be6a7b3cEfdCCeE8215fD8fCbee724",
  "0x2694c4766E06d18710ffD7Ff1a2A422F9aF6173A",
  "0xc92C0066300B29b0ef7A023b4aA4d3651Df28c0D",
  "0xdFB5A2F8032D15a18673B16069c28434C97fd07D",
  "0xb3126d09EbF9B5de9eAf9937BFC3E5DcECECa856",
  "0x58E74E94E836D36c27fdCa268d2f0fbd4B76b24A",
  "0x924848540dfd66297103865e0F7C1F7DBd3472c6",
  "0xeA6AFFE5bbbe11cBf9B14d6Bf75aA3b31CF658dD",
  "Oxd54EE86A16e6aB89d2C5FfD47c74a1074848Ce6C",
  "0x1A0Ab2a8577FD4385E0B30474507572d92072151",
  "0xa40cf26D19D949d1259f4E54bA79613F01a49a4f",
  "0x96096A23fC85f09D3532C6ab90Cd1aF2341C66bC",
  "0xEb461B2AEdC7FefFEab08555C6F00B843f54cEb2",
  "0xE1b6976068114FC08cA69369C92a7c377e7570e0",
  "0xC5512beCDE4c386197E5ae75A6a9e8aC1c39Dc29",
  "0xB3384DfD46FeFdF209d2C896D3e06e584B304c2E",
  "0xedc74b4351FA672d25B7A98aBB27BFdDE6A81A9f",
  "0xEc1A017171e68f31Faa6859C1Bc042eF253Ff034",
  "0x92dD50F1d162dc80bA02d57e5F4107a0870dfA22",
  "0x2e12D96942D7a2a9a7Db20bD4e078DeBD73E9409",
  "0xFe8D0d3bdE63C9A9c67fD3F81549D78CD7F482CB",
  "0xae394C0CaF09D875b8ebd786B33B577492EDdC67",
  "0x9957720534B42b2f7cf6a85D060cDe951799De2a",
  "0xC1E04a572Db3564769025EE6727Bf22AAB8b42EB",
  "0x154fe6e82c00ef6bb77dc84cb24f9ac14edf10b5",
  "0xeb3D22F8D84187FFAd15Af426427F13a9632aeeD",
  "0xe6a93e746C702b8F99d4d5977C5DFF488a4b1261",
  "0x874D228D3C44C6722dC0A96Ce0C8c3DDa9Bc8953",
  "0xA5C15861F3362baCBa79660eBCC79efA9711707B",
  "0x74433eDe05E90B31d2c36cfD0be51921B438E500",
  "0x52C7f99721627Efa1CDB9a4A59891BaBC5258d29",
  "0x16DEa9c6065bE49cB892634B495524d0953A2464",
  "0xd5f4EC6C0d0e8970ef18626B27F2B4535f88b39d",
  "0xd8Fa1012519999ACDF115eD8A89b3Fad3d04b673",
  "0x13b209f0444081c713a3d7510abd57a7e2D0c18F",
  "0x7529384AC6DABca52858Fc4B0592821c5023f007",
  "0xA46c5472e74013f2DF3e211DAAAf55cDc4e09171",
  "0x89fFD7DeF9b30DBa8C65E003Be3ce1aB6227202C",
  "0x92b354137AFC70Be4B430d54C5e65A9F13fD7d60",
  "0xF6bA325f5Ad7671F3b77CA6E624093fF51e380d8",
  "0x77c0A351Ddd34dCAA928Bd4268f4d816EDB5e749",
  "0x9dbe7Ef6ad6078d9AC3E826Dc8DA73e697ceEa1d",
  "0x98eB0Aa9260025BD788847bf9546c7C85797f1F5",
  "0xc5cebe0F3b5bE2A065F2Ff51676E7313286E7CD9",
  "0xD13ffb06B0954512fD6b4603b5b423B0a52F91A2",
  "0x39B589eDA76b8e1774f297Cf4CfEf335a558510A",
  "0x5f6CE8ad053FF7E64AA9126FBeb0d24207f84D7e",
  "0x2b5a066c516692854461203B16e6622982DC4165",
  "0x58F81D396468FAf2765E5570B3ceA4a24B9510C5",
  "0x6038F2CBaAd0Ea870B94DA8285F1B8BC7a0045bf",
  "0x3be6Fdba1DE64E67180C6eBeE9410A3E4779e267",
  "0xe33e2bd61d89b5b394ebf665187e5ba82651ac49",
  "0x5c277EF31AEd7bde19632083CF94fb00308b2DEc",
  "0x04c13Bb97fA5F6e81BCD8D15cC82e4d9c8e442F8",
  "0x743b2997C63b98D7F62062c4Cd199b2598972c48",
  "0x33F8d62CD5e28Fb8B11f868C07674f7181a5d473",
  "0x71114f0146d299bCEaBA41E1892D94fB6340BFE8",
  "0xbcB8915892c8597cAeb9303560858f97e02881D8",
  "0x3e94eA70A095f6bDc9d1D7aEA22847d53542DFa5",
  "0x303E5E386d5a79a28D97e8Aa098a2C178F83688f",
  "0x3080046b0f3007F2678B2FB35Fc6f31FFE2C3742",
  "0xa616CbD3B2CDbA9885684C7e3cF53050cca4daA6",
  "0x1715a215123C0E013406642D74Ee4fd6C9409e30",
  "0x79C8fBb16A480b513300fB378C06D369Ecc0FA6e",
  "0x24b1Bc44D3415777e7378487C78c8DfaBd47a3b7",
  "0xF4DA14D4283933CF8b57ebeAea1C93Fc7dA14802",
  "0x891fD9ffaBFDcC4689Ae454c830a2fE0F24951E9",
  "0x8fDedC0320a95c5a16e6cbdfa6700e82E80f6bDb",
  "0x008F783d5fd3b4E0739e21A07fDDaDF144eE5294",
  "0x4B436b69f78037027cA730a6d0028f34EAddEC0f",
  "0xd9a333D6cD024aDd2e5DF2CB71a090BC10156eBf",
  "0xEB48f045aafEFfE901fe2afa8F3065818f22Dc89",
  "0xF6a05592Dcf0A0ca6111d5C7e2f62b9F60b81261",
  "0x50025f42f489da0496D67D77bB93a97FF7450D3d",
  "0x580ffA61E036FC1a968ab8a43E25251156C98ef9",
  "0x044399ba7d0deBb2147ad34e55931417A7EF104A",
  "0x99571f37eD3cb03ee83af24B4131CDA238aA610E",
  "0x7AB60415A6712d15DcA1E08E9B72a56b8cC97c77",
  "0x958A4190B4c385d5E42b2189526d568e57785aD9",
  "0x98A79692D7f574E0510B6d08Bec846e83E9827a7",
  "0x7BcBc8C1b9d43C0EE76f840Cf8092176Df9c64A9",
  "0xa91d28774C9fDc03C4f9830B2F0Ef59c660cF472",
  "0xDe8b4b9ac759D1665F36f0a8462960B1170Df29F",
  "0x519F64C09d72967FA1B40f9432148aEd776D9717",
  "0x36839f9a73C8305ad4626f712cB011FD8B448310",
  "0xC7d44cA605eD597517ce47b6589Cf6b0bd0E0252",
  "0x9b1aa52daffbcb09312bd00fc20fec3d150befd7",
  "0x612Ca623e264782e3Eae3E6EFD589c2FF5A2b51C",
  "0x78Fe2c1DFC3F46645d6f2cb390a0B8F4776E610B",
  "0x44712a0193b1f1ce19354Bd5e09Bd87a741Ff7Cc",
  "0x798C962c105C024E8e55F022cbe006D3433A5770",
  "0xe5D8D3fA216D8D55A2f438d1686812C64f41f1Df",
  "0x914463A275ACfaB186b1fe4824Dd0F4Ac12952B0",
  "0x6D1afBDDB65A76d188994dF7DD6Ff47A65Ee3888",
  "0xcb95B373A5dA40925D5f7e08DE6F55efB3D8304C",
  "0xE5e8Bd70E888d26448492758E7b7a7efbc1f9e07",
  "0x868E5137f82e8686FC74EF94539c4995121b5fa0",
  "0x6F6a66f29B005b9db247c0b147F7D518477c90a8",
  "0x3356d1B05D3960Cbab2adc8EEbc1AE05F2A31F63",
  "0xeD37026CCB2eC13D6cC9f2c5Afe7B86e4798B953",
  "0xc7ce84b226aB63cB57228b8721EF24D8D2fa2A08",
  "0x2d8aB7572B8d868556e6D9b5E0711aCFA6687A17",
  "0x12E6388e6a861Ba54aC60FBFfF4F0Ed3921FeE8D",
  "0xcB49c6b79F0580e1c15c54319Ece560A12FFf79d",
  "0x082a0B29056B0C0CeD1D5B45D2a7cF288C4999BF",
  "0x2F7ACb98D295C28DAE8103809121CcCC0Dca810C",
  "0x7EF92CAC8A0d84559d1DEe8857cf6a0A172e969d",
  "0x2d5b72E5512BEAc98Ea21e5CdA442F580789f64a",
  "0x5ea87a5fBF34Ff92A6ED06a4dbbCC6Fe935E0a6E",
  "0x2c4fEB03d32Fff06522Be12bBff4DCD1A5B160eB",
  "0x1eFde3cF488EFfa5D3Dd2E4bB1cBEeaB8a8564cb",
  "0x5580942C7eeA4eF2A85a19f565C9e345350Db205",
  "0xe7fc63Fe11a08E80ab09735af5FD6bAC025EC0EC",
  "0xfC24306CcDf7b305005D7826cb4Dc5529ADE9a1c",
  "0xBEDDF3bc4C6c0a4cF9E8386c39b6f338ea23E68b",
  "0x0D0B3EF1487272F65681905063a35DB8CdC7d2D4",
  "0x6360fC82eB35E9557FB4FB4623e97C206346F6Be",
  "0x676ED669A00298EE45a2FB81D7E629388ebE9f8b",
  "0x8B850085264C576d6248C0763B3de6C827d43a95",
  "0xa03a3F7045a32e783c014647e43ab5E46515177a",
  "0xfA2b615a4d773a43F14E13909491E1c0FB7C0eAe",
  "0xd0b2e23fE87Af7450B3Bb76Ec29AE98eF404B924",
  "0xf446A62DD0b9e502eD92cE103f0292CD95c97EB3",
  "0x7d0f51d1C0Ccb5F15C89840f708A958839b6f2FE",
  "0xd80090c6c6e45ee8ae141215ead4cb63baa9882c",
  "0xbB4a8416079d1854b1591e6B87D680F85d9c7d8b",
  "0x7395987B2853E625bd12b43AeE453106089daD6C",
  "0xF26E179a86534B59848a175D623758E1488A74f2",
  "0xbff8E617758b30D160A2a8301A0DdE419a1D80b1",
  "0xcc1f01220b9D9a2BCABB87da9CbC75E2B8A06B8b",
  "0x65fa6174d8359e0640d26c9099641d58fb794cbc",
  "0xEa4a7d37C4f073Dd0B9feF952D5c6e1326A37B22",
  "0xf8269dD4D2875d904fFD51e48343006539D4b55e",
  "0xBA25652a435E0C082422698f761d388f7CfD40DC",
  "0x29426acF2f2B634685B5dDA1b68BA25BB3dcc806",
  "0x495515D0d3bba4A66785C1209f2Cd1c81492F928",
  "0x789d824CDaEA0100b0a6dfdBA01951f151d1ed14",
  "0x9e7506955FF328f9838c5de55a09A22ba70AF82A",
  "0x5c051c0FF69b6f5FDD47E847EB370DD48726Ec4d",
  "0x07E7Ea4fDB7aD044dF3Ffd88FdCc66f67097b432",
  "0x343ca3a00A0f1Aa5e06366A39C0DC64D90a79cDd",
  "0xA2BA6C51bB4A62717B96A9E1A74Ac29109E99623",
  "0x78ed0C1082A6535c8cbcf762bCC16Eb6A4b74e86",
  "0x81D2Bfce4397290b1F142d816a53A33B8824aee9",
  "0x98f41dD4CC6a4C33e5645Bd02638c1A96e8d9511",
  "0x240b413D83D66Fad7c1F6c8af305F308FfC0BEA4",
  "0xBB5b507358783F1d7a0a5E5D2FdAf4f215A25B85",
  "0x83731F7A05D58eBC298aA2d3E2b1320cD475dC03",
  "0xFCF59ceF839A8Bf6990cCF678a0919ADe9853ae0",
  "0x248dA3A79de26Aa5b78547677654c2c0d13E9271",
  "0xD8bb46a667669c99Eb539090e0D2Cf43e4A1A5d3",
  "0x3c5e37382c7EcD3EbFDebF6B3e08e9463fc24F49",
  "0xbF4f21CafeEdFE46Efa4579c212BF69945feF709",
  "0x9F4832fCeA35FEE1152A7C236171028eeA8c3dCD",
  "0xB50C5c2C30d013355dd4731D917374c2536d5c0F",
  "0x7636d51Ae913beb5e816ae31503B0a061b49611A",
  "0xF6Ce9B5DF69c506ECB96d5C9D955C99Fd955A5c3",
  "0x1870ee4c767f857a2462a979e181698e5eaee609",
  "0x9C7102Ef8cb83C643c757c90f04c7b5b92487fdF",
  "0x88a82ef9bb535340871d0f4981021d22ba3c899c",
  "0x290806979E5065BafE694862EEeb1f49732b9CbB",
  "0x90e3AbeA97D66271F4Ba2a48bc3986Bc2a1D201D",
  "0xfd73a8dbB4c263230C5F4d1cf33344289268290C",
  "0xd4CFC5A2492Dc9CccDB3F445Bf6C726fB07f7754",
  "0x7A55D045c24bd4d8AE2063cec9e1d5DE47c74893",
  "0x13C939df6A70c568b90FA0BCC40FF84C5AFcee96",
  "0x4BCfa010F0e9A5A25C171f4eE37B351acB2ace8a",
  "0xF821E8ACe60CcC3FAE0317f95988b54080921726",
  "0x0Ca132E7Eb553b30f46e550aC285a5b262d7a3B1",
  "0x129AA9B0faFFf5340401173fcDb33a5C2282EF2B",
  "0x5d11d23D83e30e0C5CD5e7d3a0a885cef54c4AaF",
  "0x6d7102430cADc3D41123c687577DB4D65280F2A7",
  "0x4588Bb1468A45c524d665e898157F85F44AaCFae",
  "0x8dB9918d3364Fefa97D4ab1FF1fC18177e583dF1",
  "0x2d4F45e678218DCaCaee09e1Fe7271A22A79f063",
  "0x535937Cc5Dc554b8652163Ec8705351b5131aA52",
  "0x573394Ab1e45980F3E017A7dFcA14a7Ec88E3200",
  "0x4fA388a3De073C50Db01865580b7dEb875B2e5C2",
  "0x4758Bac8E85eD7150D45161687152F2f6D6f9cB5",
  "0x243451Ef242201a9fF9925f6BB0E382B8f0514C2",
  "0x7974cbfE7001107DAb8F1F616C12A7f5C4bDde7a",
  "0x32DF160ccD77897C6Fe19Dc44095b7e0CEB9151c",
  "0x7999704f45a9f95FB914F8C08427a7fD65375AdD",
  "0x0B0d7B0Db048beECc3A76BBD1428877dfc4b47d8",
  "0xc3445B5440a65a638Ae3C9c7aa7167F459A521CF",
  "0xF5D09FEe8477Dbe34123e17A52f05ea4aB137869",
  "0xc4dd3b2EC6B4B99E5144841419e24dde44333D02",
  "0xe34a3dDA68988076F1Ac02B7B62e9a264525615E",
  "0xd2Dd94d095247Ba6520dD37491f2c7639fFBe74A",
  "0x4FDB49033C916b9b09BCd0850F2240d610afF726",
  "0xD5154470c0CD160ebF070B083D1befBDD12aC7c3",
  "0xBEbf53131E47dB582f6453a7CfA8827D59F7A6af",
  "0x4eA42F06802EdE1849FEe3d5E1c87A5bD1AB7597",
  "0xC3D3e3DA141EcCe9Cb2098A7D44355B9f0b26eA7",
  "0xA4E4D8dF99d5Aa8a3819998d07794172F95F5C5d",
  "0x058C5C06d839292993CD6842628C8c4a5aDd5F88",
  "0x88191462Ecb2219F93d5e8091B8D2311DDc96a62",
  "0xc5b795f8cB4D0639012F5aAD20E1E56e9024f7a1",
  "0x27cf7b699114Db0eb5f2123c85DF4141C54A15dF",
  "0xfbb137555Bb48B874Edd6F4C56B99CD98Bc963bd",
  "0xB280867772D520260EFe4DFa4CE139dd38bC3756",
  "0xD13718414368f07F1B582cc88905fE5E9aBF036B",
  "0xF205b215F97A9e27A04fB4d5E8CC6AC15c2Bf2b5",
  "0xACe900827e10b78BFE7Ab4eE9AfFE8081D6328e2",
  "0xD708164f4c5E3F2422B2C57d5c4e39ae4c286ab2",
  "0x593453A58AaEfe954D95B459a1566759D57Fe123",
  "0x866E6B8C7fA693cE981862E3e6cb8C5831909EC7",
  "0x7881662E7E775FC60A326f0F1c26400864fAD1de",
  "0x5A5cC9daB5Cfcaaba2316d8628A745aC60eedD47",
  "0x31f2558b405BCd2c83b4AdDffF1fE05efdB6d8D2",
  "0x18DaffB93902Ee2c20E48B664bF3b11315eED69B",
  "0x96694b95ebBB8b916Ac9663F0904a5bfBEd87A91",
  "0x55aDfB56429eb36b39723800f973DE0A06657cf8",
  "0x3b8463A6f13E02904888510049b455fcD5cd86D0",
  "0xB90aBD4d1069310957b3F3f26f31dA38e697B370",
  "0x3fc1C25a2EB6d13DbE4df64A6C8EB247F11DF1e9",
  "0x6522F425D907eB8236c141c983322b3FDD942d15",
  "0x0ea2b36489c1d5c796cc377637fe25848f3571bf",
  "0x2884684B9824D9C911fdc3d64863BeDD49d7bA8B",
  "0x8F069e7021CD12c2e003E05bAdD207854D5cA4a7",
  "0x5D24F0a214E14D79cA728a776D2f03FD7a35db66",
  "0xf1F87899472db15604Fd64daA5b04F3d3B0f0C09",
  "0x852168c8007edF16109072Fe110C09c72E9423f3",
  "0x5d0e90278747c43ed8d58b736e6fb713362d04e9",
  "0x1ab5a45c8c36291c32fbdec98cd551ae5669b122",
  "0x0536362Fd93C613d310Bc1b0C0C619d0dCf0762b",
  "0x6d02446e1416a589De3F947C9436B7927c21688A",
  "0xA546Ee534805f9968e5a84A9Cb48860779E45E13",
  "0xCe0e4dd7d3829f403140dCC5B0E7AEAb342F2dDf",
  "0x08b35d6cffFF52e3D619140Ad88ED43d9C6e66D8",
  "0x9cc1d4af4bd2f9123e66433313be82afa802393f",
  "0xd4AeA428FAcD4657B0200d3ff39243f7aD9b66C5",
  "0x55EAe205EAe5CDa5BE9F9cAE3fEF7Dc6e236186D",
  "0x0BCC55C9c9c1f8f973d6b8440E45a9907C0B7Fa3",
  "0xa9CCD4454a64503Fb243ED7960c0641cb634c634",
  "0x355cD95466f48fee06A2Dbc10c58BDcd8F9818E3",
  "0x5ca0b13806a475A7C3494C88D2850d4369C1C447",
  "0xd65D6643fbb488dFE4E317c81ba3D54737CBc376",
  "0x14B1B6bB769c584b1f4255Bd2BCD928CfC87F852",
  "0xAA42c0E9b491f27cFF7571C44C730a6b2fc342ec",
  "0x9955d5df9e0d4b2d653989486eaeebb88e43b904",
  "0x3Be09B2d176bf652fD45C7e7432d7BE19126E6F5",
  "0x39013A53884593e7d79E05f79Ca536A8C2a33369",
  "0x9B8EA91094e801B305Db0C26a7FA792389167447",
  "0xbD1eB2C0aB87CA8cAC7d5dE1dBE1644A5633E692",
  "0xD249926D1b68e3AA07fFD1E68B64625b996EC200",
  "0x2e656f6c26Dcc7A84C85dD377A11F9E02F4F460F",
  "0xCeE07a0f22095cA1F99c735A9b1f3a72B998f24C",
  "0xF1783e6150E375916F6003830d7390EB7922aAC1",
  "0x7DEA578B1B46BCca873251EB54A0543b46b749A6",
  "0x4debeea0b0c129eb6370426704d43fd4ea1176e7",
  "0x78A42355392f372246eD7aa75f8053f1F87637cA",
  "0xb30D0EE3Bc084A94765e526EA9DAea37785CFaB7",
  "0x0220bA7A02f03a88E436c1a2d28398632e9Afa4d",
  "0x5c3d97f9a3b7938CA043B3a671b4B05291a94823",
  "0x59875CDb45A0d4d18147faC9E39Afb1fA5fc9D0E",
  "0x6506363e6B829d46760EB21762D972697e522058",
  "0x4cb0ECd531161d8D01a1EEA84d48F4776a7EA795",
  "0x31393903Db49a301d44B0C5336389cdBcCC34E68",
  "0xe365226F4f2370a8Aca8a9cBF86412B5340DEC6F",
  "0x5C52882e78734259195F6107c6B760b61f3853ED",
  "0xf1FaAFA985fdC96513bA4acf72D0b6Bf6903b7Cf",
  "0x5aee5464960327cbd86adc3685a8c53152c3058a",
  "0xFdb2E7640375f0ef8CB2b3E6Cf9469Aa457831d5",
  "0xcfdd6cb5aea4c6da545efe9201bc8c3ae959aa79",
  "0x7fAC9D0473aB4DE2b5A40D1efBcFb3Ef60A5c87E",
  "0x44A44dAAbF903bf6B30a75B15e1b7772648C1529",
  "0x82Fd3d04b25A13c3CC2B172ecb99394AABd05F64",
  "0x0bc69C9Fd691e736fde26571a1FF7E2F3A172923",
  "0xF324323Eba7204457126619Ab11978D53f6354A2",
  "0xFEdD247a59eb459823784578a2c5B30499aC4932",
  "0x0Dc1949E3a7282c293A491b1b66756aa65DE7e55",
  "0x2aaeC4e7Ab26BAFECd396Bb728Ff313d0d515eB8",
  "0xfE37e4B64fe8B34D547BD59B1492e404783020cC",
  "0xD78C6FE6CaF6A1e9939A5d1e97CB534a970041a9",
  "0x3eC756F5B382254F87baBdD39F795B855baCdF9d",
  "0xDe4C4d1105a67d9b7A86710E275d81d2C33bFAb9",
  "0xbf78c93CFdE5500D1cf6BD1e0EABC2Fcdb2770B3",
  "0x7EdC415Ef66CB305f4be052965b245e0010D325b",
  "0x4d1844139df16e0bd84c219ca8e22e7a1e70f8c3",
  "0x156044c788ce3055854194baa33fDdA363F529A9",
  "0x901AB1DBb8b143D9f7C0a2E67bbfc2208f51292c",
  "0xD627D9B6bbB3aFB5f81092E3BBA948D10d468723",
  "0xFc5afa246B0e227B267805c5963B3869a2d9da6A",
  "0xbD95E2ea103FD2B87a7F8071d76D561B55bB029f",
  "0x77b67d6a6559ae094B71ad685cD496853f3DCD29",
  "0x3cf78784dbb30930cf49be06d4e3adb59201ac70",
  "0x4318DFCe151A6da49982Db7076406124e9F4dCdc",
  "0x4B8cC51d4554bBfa3c91AA9D17911fAF4a2EE7f6",
  "0x9aD5f1545c1e25d3045c5f991fe40eF3d0eD069f",
  "0xaDD40D80C6BD87733E5bD55a1942c6e3Cea4e920",
  "0x586CF86563F6FB8c9B69aC89E1811CE0F752412f",
  "0x08C3Ff89ab705431d513610Db2B862015Ba872c8",
  "0xCc05fe80441C78d6cC66bed15a856b53F1A9Dc1C",
  "0x11d9090e17a2abc00e1a309f42997b274e543cb6",
  "0x2a2F3D9a777038cE331ceEeC14472cc6A37ccd62",
  "0xE2128fCbf84222f73137cf9bb023d0D3a3693F1C",
  "0x43D55522e4105EbB3e81315A69dc28C799b54b7F",
  "0x0CD62C7b2e617152E4e4B65f89d047ADECCd8812",
  "0x8FdB9205DdE0a689FaFB06569950b29AEACdC3Ba",
  "0x32fDB0e48E122140202a0B90d5744e2a83216b40",
  "0x2a8482a8e89C1D4aCdDA32FD232EA425eeb87e60",
  "0x2591b7FA9e764f68c1f6410D33f0484F68e1df0C",
  "0x2c6Ad77951E2eee4Aec061C7497e63b404F5625a",
  "0xAbcA9075Ee61f24A51f9b2BBa892d1D1B26cbCBb",
  "0xFDbFef187eF4A15Eb17ff11a4f05E9DFC20dA46A",
  "0x63570A9cF17dB5CA1480E635249563274f13057a",
  "0xEa63e594E33feE9B220035493E0e765ba7C42b83",
  "0xE169f28e52759537D35fCDbE2B89952c5bB39c5c",
  "0xBE255941C2330bD7BFF25B78F4A3604712467658",
  "0x3B1cF67891753DCB5dd6271F3bf0520dC9Cb2446",
  "0xbC9c0345AdeD30B5d0556969CDBC095f33F26D37",
  "0xAE6C486C3aaC0d267a31f1B522081feEaA9A9451",
  "0x2731E7F53d6cAcAA5103e3eCa577f97a94e0af55",
  "0xdc2a2E2d70daD0a65a4516dAb1EA53613871FE72",
  "0x04131e8d773acF5641F1CD726285ae1a2b3aC3A1",
  "0xdCDd2D19896EB3e6AFF24a60cBC438C6B00b946f",
  "0xE00973e3954135cCB87fc18C045dcf19585C48d4",
  "0xca71A764865637942AB812067711B6E607404146",
  "0xACFBe154d168b43C149213A956B80f0FAD2E57e3",
  "0x5BC818B878D9B196980B63208afE02570108e287",
  "0xF41cBBAB175b22F0b9017AC4088E2D5F04B5C669",
  "0x050533cB01Ca231D875E28D6CF9BcC1Ca4129D0d",
  "0x5077bBA58982d9932F486BB832ef639C0c603328",
  "0x7B05286C955a954647f7558C1C3ee35Ef0c6bB3A",
  "0x5aB4881eC119292f29965ADe516d472cBa887FCC",
  "0x1830ad1b6BC50f65989428d885A5823B7e081257",
  "0x00317E3960Feb35AFcF09FF76C0AB38bb526A48a",
  "0xB6cf6F40e50B177AEBb698B36a17995154D3D1B0",
  "0xF092F3f693B0cC613191Dd0cf0A3928e700400dA",
  "0x036F225Ef47aEC0FfD4D62af4A5Da964B2E60506",
  "0xEec66737E685278E6a2C495a6Fe4679A14c68F35",
  "0xB02657C27B175C7b62beEB86694E43c0c1034193",
  "0x5E435BabB2c41d2c84d2C4285f0Cc4032c553a33",
  "0xdcc37ca5632b6708d0e6f456f379625424271659",
  "0xCE520181607D93aFB34e7AeC6Cf8EA766C4581Ce",
  "0x51348B3d639d023171aeFc61870C3c250a52c54c",
  "0x9627972Fb589Df51c5F29AB627cdBFFF1Fb35cC3",
  "0x53aabd4f478dea6c25880c7eb38be22ca7110d4d",
  "0x0acbbE0bffB75aF50AA276b76F02Ce5F8a1Bad0C",
  "0x7b8b714b4242da1ff5f76ef0814ac55676196437",
  "0xaAf0eb7D49cDdBB8D6d516689b9427cf69239C9F",
  "0xFE733F61A6899A660061Cedd0ba3712e090eE1A2",
  "0x2B5dD68c55a4166B9c7eF0bb581f9C55125ffC83",
  "0xC026CC1f3571d6fCEd78D510E9Ac382B49C3419E",
  "0x6609eC70BF04f20DDd720470B2fEDA600427fC3A",
  "0xF195DCDE1863Ac91268EC43D18aff0191A6fA44A",
  "0xEa68D8CBB91829A21Ee9E7E38320f3f55558337d",
  "0xB224fAC3456408EaD8cb9AdD7ef1878887fcDCCD",
  "0x6E19f43C36DFbE7D6438c00466b4dE3cBF857DfE",
  "0x2DF1667A0287c70b75E4047b7b05548154947072",
  "0xaa3e9CfaB55CeFD688a3Bc45d8e53a73b7D8800D",
  "0x8c9EffaeDcCAf3ae587d9B00E139c9c4398e319C",
  "0x005f9b4f14b994d69737f42176dB734D0ad7053A",
  "0x88F115779a78403D81D140CB39E815bff4253537",
  "0xe7C1940120ef6aecc9ea25B16aD25885FeF967b6",
  "0xcC77B2d7DdA72CB3B850b85D1A320F326436868B",
  "0xf20837106213a6366596179b7EeeD81D22282Aa4",
  "0x16adD04EaF42303Cad0002C60dE34C7D0C4372Dc",
  "0xa8102799729c625bd6d4bDDbf8c33ef7ae1333Bf",
  "0xe2bc1a2a7976d1aa118d76f90f4a55d077c64c86",
  "0xF05b026855C8FbC0a2682cbb1b610BafCF54a047",
  "0x7c4bcde06127527970F0ea3Fd263b7fFF1F860b5",
  "0xD1385b60b7f9B32196e90F554b950b86714BdA50",
  "0x65610DDB7157D4412B84B2D0c39B03986C078f11",
  "0xC6Ff47dCf98c5BC7C8B18b44dA24CeED19fF328B",
  "0xb25469ddc31a5fb881454db41529bdfe186e3e23",
  "0xA0F9f97fc0b4Af42E4B76551d05F8D2Cc8EeC9e8",
  "0x882D23171F35E9620dDec45b54f56456F85fC613",
  "0xdc48dcda07955a9c1a53c2e0d4163286764c4cbc",
  "0x0f1f87E9d573FFa81C71661b4A7838A89E4693f3",
  "0x965Aa913d5eE955cadE0aFb98253B676751818BC",
  "0x82A4ae53F9883f7beA6d771A7d5B3ac6e93278Ba",
  "0xC06AAbAD85ecccfa35CFe671D6DF90Ac9D46619b",
  "0x4D465602B72F7669D251B1d9187088F82D7A6de1",
  "0x4E02A3818bf2B5fA36809C74270A93131F87E432",
  "0x1D3E52C1217458697291A6839C8aA3669F60239E",
  "0xf37d253CDFd45305fd7dF7FAf4B83Ecd5d467583",
  "0x747c46c84a26E690fd85fc5a555B1f0bE3f32cfD",
  "0x11A3C12C8E6672F8504C0cbF9f9165093Ee97B4E",
  "0x5964e73355f9c44e01175bd868da4c81aa018b41",
  "0x6BCEC25aa22C9216E9238d7A0Bdc234c57d1FCDf",
  "0xDD12A0c04BE3fF962E7321f11Bc08DbE227c25aC",
  "0x0ea975d47160AAD0f82A6e43C1e4B8379944D0E0",
  "0x4A7623816EB584d4F3E53cA884be1350cD079202",
  "0x49025F0E9d0d1CB1e600F4036CaF562aF6DfF44c",
  "0x2b6BCd9bf0df7cF30f10dD193ca6532e0929F682",
  "0x6Db1E8794f30BB7A21a08048bbD0D83C0DD5D782",
  "0xc78EB1A0d46B16701C560C6BE8Cd73c951c606a8",
  "0xec2CaE5e5bAe2756Ce8B91441c49c43a019a7cA3",
  "0xDB021CAB4A15ac97A74bab64c74b239EbE4210E8",
  "0xc1692cD69493436b01cddcbE5FeDbC911746A7C1",
  "0x351a7c1322967326803cbd229a4654A2d1a4cCcC",
  "0x3bd99cB7265E946FeB1FC29b8dBA4334D33f8794",
  "0x6C889A40Cc7111c7BEcc927b37CCF2c5f040e37b",
  "0x6d0b7e7e27f023b9f281291386c2E307Cd366042",
  "0x0F9831a88ABb0Ea6479f19Af7AB6f53A3C490f72",
  "0x048eb03324123c8413993d0517542c48bfa35878",
  "0xd6aac01503f0D12431A2C418048Dfd09b57377B2",
  "0x61095ecbbF439E5599b75fEDa8C6F5f105Dbc3a4",
  "0xaaCb0Cc990DA7F9aeAE32d9a652C3e0AE1647b5f",
  "0xB728fc78221E7300A9f5548B43321AB1A0A8e8f0",
  "0x8188c792f4fCDCdBc0a673A0F4c8D6a667a8D661",
  "0x9C910334e2Dac8B46fAF969fa7a2275f4661305a",
  "0xeC02dD4D7f2Fc7DEB37f1f35d2058AC6a621Dff0",
  "0x06E1C6B110C4b1E36820974c23CcEB0f6D4D92E6",
  "0x01a18B4ADbc7A0e19fCfbdf63E85EC6F3c9ce9e7",
  "0x99C7DeEcfB65b327bF6df3DF57eEDdA19C5852F8",
  "0xCe0233903111Adc2D90bde8AEEdDf6C77F731A20",
  "0x07CccB840D952EDb77ebdE6590fb660aD4d649Ac",
  "0x5Fb04dDf12818355bd40E096995cB383e8F83E19",
  "0x806881c608aF83FE07CD9bb7bF5De9eFf9C44611",
  "0xD4A7aa413b4b5b2bC26343cf0bCFbF0BB009b2A0",
  "0xbFF49F00F46855b9B5Fc6d684186089Dec1C36C0",
  "0x5133601d7c9329e0f190851c5b94d5014d40036d",
  "0xA6302A89Eb162e5A3986bB528CBBe7144EEA649f",
  "0x89a8145E5fc02368D7463031E36EaE40f660FA8E",
  "0xb4dcDd00c438fc2930ccCd684c9F175d80b56B79",
  "0xF0D70731bbACb0310D85BB0bF03C59ee46a1aE00",
  "0x5Ac7aB23e367b4F3a07a44a1DBC0b12658b9AB4d",
  "0x8B02A107611E278E83A019efECc079F1fE1E03Be",
  "0xFBD4C17C3E468F67c3D48ceaF65255E884Dd298c",
  "0xEFFa0B6B2Ed1A91c14c281F23eAbc4e2d7Ff6FCf",
  "0x539fBCa654EDea3bE0f0F4Fc1a71093E0B8dc001",
  "0x3358E4F40d1A33CB88342B09910C6d9fE7ee9DE8",
  "0x37D4F083aF7FBB633DC804F59053f57B410Bf962",
  "0x93cf1EaB24e5208934167DF5B2fa21bE4C4150A1",
  "0x63edf628Be1144082eF888A2B2E0A75109438B64",
  "0x66E72223E8B1973B38D63fc1295Ad47BA7A3E24d",
  "0x54105EA638e900f80f3444a1562A92D1a29DB1Aa",
  "0xc3D3e4Fc0A46B0923C038b84D4DFb50A2BA2724c",
  "0x6Ffa72edeEb008bc09817de6F9A60EF65d2d2751",
  "0x0903d4add0209CcB77ae317828D65f0F6f6e351c",
  "0x3938ac5727fA3D2dBa6E49aAC89474891257f4e4",
  "0x45d7eA9f1d520AC0080f670E6C535eEC937367F2",
  "0xA1a73D3eB156B63FaDB92a9025723256235E7d7E",
  "0xa236Ff61eF37D9A69042Bb5a5eD637bA89aF3c0a",
  "0x16472aF8405E9D445dd0D5877621d92e5d1341Df",
  "0xB46E0C7Bc15b3E734483cd32CAaE74b8920126Fd",
  "0xBb02eD1A3C5652256014a65b16068f48F1a25A8D",
  "0xD5866326Bf02a19fF5C9Ab6A93D8648920c67Cb0",
  "0x1473444518406b25078D9AE7a7b0eb3158A68e89",
  "0xfe8C928589fcd62fB23820EDDb3a03Be34aC206E",
  "0x9469f54132a8449E9e6708E32082FAEbDaf43C3A",
  "0xBA48Bcf58941472FaD93cBAF25e5862F02BD0EeA",
  "0x52228e80A47Cba170893019370879259304114B3",
  "0xe49d5BE6C9f8Ff32bBa6Fa0ec26C8b9BbB23b0A8",
  "0xe33F06B81DdB8042A93648b4C13F9CBCe8A04c42",
  "0x3B3da81711D765Bb232E4b41E18eb95c124F41f9",
  "0x2a637f8dB46474ee04159B33C8451320d70938cA",
  "0xf9a73CdC5Fa04135d05668602d4d04247A8a0c8C",
  "0x7cbE684Ac5b29437CB31FD1B17E1678653a8aDd2",
  "0x8C97665bF6888A84668144bC44caaD0abe490d51",
  "0xa8bA35e3e5d10F1AbcBE619F29a0946Dc9D04498",
  "0xFbEa77B50218B9C438722E30648b0aCEDcF52D41",
  "0xe2Ab60A720768fA7291eCda39BCF488CB0F2B29B",
     
];

const WLMintAmountAry = [
	
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
  255,
      
];
const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export let hexProof = [];

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
			if(WLAddresAry.length != WLMintAmountAry.length){
				throw "ウォレットアドレスと枚数の配列長さ不一致";	//長さが一致していない場合はErrorで抜ける
			}
      const blockchain = store
        .getState()
        .blockchain
       
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();
      console.log("Totalsuplay : " + totalSupply);

      let whitelistMintedAmount = await store
        .getState()
        .blockchain.smartContract.methods.getWLMintedAmount(blockchain.account, SaleCount)
        .call();
      console.log("whitelistMintedAmount: " + whitelistMintedAmount);
      
      let publicMintedAmount = await store
        .getState()
        .blockchain.smartContract.methods.publicMintedAmount(blockchain.account)
        .call();
      console.log("publicMintedAmount: " + publicMintedAmount);

			const addressesLower = WLAddresAry.map((x) => x.toLowerCase());
      
			let maxWLMintAmount = 0;
			if(addressesLower.indexOf(blockchain.account.toLowerCase()) >= 0){
				maxWLMintAmount = WLMintAmountAry[addressesLower.indexOf(blockchain.account.toLowerCase())];
			}else{
				maxWLMintAmount = 0;	//no WL.
			}
      console.log("maxWLMintAmount: " + maxWLMintAmount);

			let AryCmp = []; //wallet address + WLMintAmount(HEX2桁)
			for(let i = 0; i < WLAddresAry.length; i++){
				AryCmp.push(WLAddresAry[i] + WLMintAmountAry[i].toString(16).padStart( 2, '0'));
			}

			const AryCmpLower = AryCmp.map((x) => x.toLowerCase());
      const leafNodes = AryCmpLower.map((x) => keccak256(x));
      let tree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
      const addressLower = blockchain.account.toLowerCase() + maxWLMintAmount.toString(16).padStart(2, "0").toLowerCase();
			console.log("addressLower:" + addressLower);
      const hashedAddress = keccak256(addressLower);

      hexProof = tree.getHexProof(hashedAddress);
      // console.log("hexProof : " + hexProof);
      
      // console.log('Whitelist Merkle Tree\n', tree.toString());
      // const rootHash = tree.getRoot();
      // console.log("Root Hash: ", "0x" + rootHash.toString('hex'));
      console.log("Root Hash: ", "0x" + tree.getRoot().toString('hex'));
      
      let isWhitelisted = await store
        .getState()
        .blockchain.smartContract.methods.isWhitelisted(blockchain.account, maxWLMintAmount, hexProof)
        .call();
      console.log("isWhitelisted: " + isWhitelisted);

      let saleStage = await store
        .getState()
        .blockchain.smartContract.methods.saleStage()
        .call();
      console.log("saleStage: " + saleStage);
      console.log("saleCount: " + SaleCount);

      // let cost = await store
      //   .getState()
      //   .blockchain.smartContract.methods.cost()
      //   .call();

      dispatch(
        fetchDataSuccess({
          totalSupply,
          whitelistMintedAmount,
          publicMintedAmount,
          maxWLMintAmount,
          // maxMintAmount,
          isWhitelisted,
          saleStage,
          // cost,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
